@media (max-width: 1680px) and (min-width: 1280px) {
  .inner {
    width: 1170px;
  }
  main.aside {
    grid-template-columns: 258px minmax(1px, 1fr);
  }
  .footer-item.info ul {
    column-gap: 32px;
  }
  .detail-main-wrapper {
    grid-template-columns: 400px minmax(0, 1fr);
    gap: 0 48px;
  }
  .detail-amount {
    width: 100px;
    margin-right: 8px;
  }
  .product-images,
  .product-images-mini {
    width: 400px;
  }
  .product-images .size-full {
    height: 400px;
  }
  #detail-comments {
    gap: 64px;
  }
}
@media (max-width: 1279px) {
  :root {
    --rowGap: 25px;
    --fontSize: 13px;
    --lineHeight: 1.4;
  }
  .detail-omnibus {
    align-self: flex-start;
    margin-top: 0;
    font-size: 11px;
  }
  .footer-bottom-bar {
    padding: 30px 0 100px;
  }
  #container {
    overflow: clip;
  }
  .detail-omnibus {
    text-align: left;
  }
  main {
    padding-bottom: var(--rowGap) !important;
  }
  main.aside {
    grid-template-columns: minmax(0, 1fr);
    grid-gap: var(--rowGap) 0;
  }
  .inner {
    width: 100%;
    padding: 0 10px;
  }
  * {
    -webkit-tap-highlight-color: rgba(189, 195, 199, 0.75);
    -webkit-text-size-adjust: none;
  }
  body.privacy-box-visible {
    overflow: hidden;
  }
  body.privacy-box-visible #container {
    filter: blur(5px);
    pointer-events: none;
  }
  body:has(.left-side-cookies) .privacy-box-trigger {
    bottom: 8px;
    width: 35px;
    height: 35px;
  }
  body:has(.left-side-cookies) .privacy-box-trigger.right-side-cookies {
    right: 8px;
  }
  body:has(.left-side-cookies) .privacy-box-trigger.left-side-cookies {
    left: 8px;
  }
  .privacy-box {
    bottom: auto;
    right: auto;
    left: 15px !important;
    right: 15px !important;
    top: 50%;
    transform: translateY(-50%);
    max-width: 100vw;
    max-height: calc(100% - 40px);
  }
  input[type=text],
  input[type=password],
  input[type=email],
  select,
  textarea {
    font-size: 16px;
    padding: 0 14px;
  }
  textarea {
    padding: 10px 14px;
  }
  #facebook-box,
  .detail-loupe {
    display: none;
  }
  #detail-images .swiper-button-prev,
  #detail-images .swiper-button-next {
    height: 80px;
    margin-top: -40px !important;
  }
  #detail-images .swiper-button-prev {
    left: -45px;
  }
  #detail-images .swiper-button-next {
    right: -45px;
  }
  .headline {
    font-size: 24px !important;
    margin-bottom: 10px;
  }
  #content {
    width: 100%;
    clear: both;
  }
  .sidebar {
    width: auto;
    display: contents;
  }
  .sidebar .sidebar-tabs {
    display: none;
  }
  #search-engine {
    display: none;
  }
  .newsletter-bg {
    background-image: none !important;
  }
  .newsletter-input {
    width: 100%;
  }
  .footer-content {
    flex-direction: column;
    padding: 20px 0;
  }
  .footer-item {
    width: 100% !important;
  }
  body:has(.gs-filtr-container) .filter-trigger {
    display: flex;
  }
  .dropdown-picker {
    margin-top: 7px;
  }
  .dropdown-picker:before {
    display: block;
    color: #3E3E3E;
    font-weight: normal;
    left: 12px !important;
  }
  .dropdown-picker .dropdown-button {
    height: 40px;
    padding-inline: 18px 70px;
    min-width: 160px !important;
  }
  .dropdown-picker .dropdown-button:after {
    height: 40px;
    width: 40px;
  }
  [data-dropdown-type="sort"] .dropdown-button {
    min-width: 200px !important;
  }
  .dropdown-listing {
    padding: 2px 0;
  }
  .dropdown-listing label {
    padding: 6px 18px;
  }
  #filter {
    display: none;
  }
  .product-images-mini {
    width: 100%;
    height: auto;
    margin-top: 10px;
  }
  .product-images-mini .swiper-wrapper {
    transform: none !important;
    width: auto;
    justify-content: center;
  }
  .product-images-mini .swiper-slide {
    width: 10px;
    height: 10px;
    background-color: #E8E8E8;
    border-radius: 50%;
    margin: 0 5px;
  }
  .product-images-mini img {
    display: none !important;
  }
  .user-custom-content p {
    margin-bottom: 10px;
  }
  hr {
    margin: 20px 0;
  }
  .overlay-visible {
    overflow: hidden;
    height: 100dvh;
    width: 100dvw;
    position: fixed;
    top: 0;
  }
  main:not(.aside) #tree {
    display: block;
  }
  .tree-canvas {
    position: fixed;
    background: #000000 none repeat scroll 0% 0%;
    will-change: opacity;
    transition: opacity 300ms ease 0s;
    inset: 0px;
    z-index: 1001;
    pointer-events: none;
    opacity: 0;
  }
  #tree {
    position: fixed;
    top: 0;
    left: 0;
    transform: translate3d(-100%, 0, 0);
    overflow: hidden;
  }
  :root {
    --iconSize: 20px;
  }
  .sidebar {
    display: contents;
  }
  .sidebar > * {
    display: none;
  }
  .sidebar .tree-canvas,
  .sidebar #tree {
    display: block;
  }
  .top-bar {
    padding-block: 4px;
    gap: 24px;
  }
  .header-shipment:before {
    margin-right: 6px;
  }
  .header-contact {
    gap: 18px;
  }
  .header-contact > a {
    padding-left: 28px;
  }
  .shop-header {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    background-color: white;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.2s ease-in-out;
  }
  .shop-header-container {
    padding: 8px 0;
  }
  .logo {
    height: 32px;
  }
  #cart-box,
  #wishlist-box,
  .log-button,
  .search-engine-trigger,
  .tree-trigger {
    display: block;
    cursor: pointer;
    position: relative;
    padding-top: 25px;
  }
  #cart-box span,
  #wishlist-box span,
  .log-button span,
  .search-engine-trigger span,
  .tree-trigger span,
  #cart-box b,
  #wishlist-box b,
  .log-button b,
  .search-engine-trigger b,
  .tree-trigger b {
    display: none;
  }
  #cart-box:before,
  #wishlist-box:before,
  .log-button:before,
  .search-engine-trigger:before,
  .tree-trigger:before {
    font-family: icomoon;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    font-size: 24px;
    line-height: 1;
    color: #000000;
  }
  #cart-box em,
  #wishlist-box em,
  .log-button em,
  .search-engine-trigger em,
  .tree-trigger em {
    color: #3E3E3E;
    font-style: normal;
    text-transform: uppercase;
    font-size: 9px;
    display: block;
    line-height: 14px;
  }
  .search-engine-trigger:before {
    font-size: 30px;
    top: -2px;
    content: "\e916";
  }
  .log-button:before {
    content: "\e915";
  }
  #wishlist-box:before {
    content: "\e914";
  }
  #wishlist-box[data-wishlist-count="0"]:after {
    display: none;
  }
  #wishlist-box:after {
    right: 5px !important;
  }
  #cart-box:before {
    content: "\e913";
  }
  #wishlist-box:after,
  #cart-box i {
    font-size: 0;
    top: 1px;
    right: 1px;
    left: initial;
    width: 8px;
    height: 8px;
  }
  .hamburger-icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 25px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    justify-content: center;
  }
  .hamburger-icon span {
    display: block;
    width: 100%;
    height: 2px;
    border-radius: 1px;
    background-color: #000000;
  }
  .mainmenu {
    display: none !important;
  }
  .below-header-bar {
    padding: 4px 0;
  }
  .product {
    gap: 10px;
    padding: 10px;
  }
  .product .product-price {
    height: 32px;
  }
  .product .product-wishlist,
  .product .product-add {
    width: 32px;
    height: 32px;
  }
  .product .product-wishlist:after,
  .product .product-add:after {
    font-size: 20px;
  }
  .see-all {
    margin-top: 16px;
  }
  body[data-type="mainpage"] #content {
    margin-top: 16px;
  }
  .main-banners-wrapper {
    gap: 16px;
  }
  .default-banner > b {
    font-size: 20px;
  }
  .mainpage-greeting {
    padding: var(--rowGap) 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }
  .mainpage-greeting:before {
    content: "";
    background-color: #E8E8E8;
    position: absolute;
    width: 101vw;
    height: 100%;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
    background-color: #F5F5F5;
  }
  .mainpage-greeting > * {
    max-width: 700px;
  }
  .mainpage-greeting .headline {
    margin-bottom: 16px !important;
  }
  .promo-banner {
    padding: 12px;
  }
  .promo-banner figure {
    width: 32px;
  }
  .promo-banner b {
    font-size: 16px;
  }
  #newsletter {
    gap: 24px;
  }
  #newsletter b {
    font-size: 27px;
  }
  main > .last-visited-wrapper {
    --prodGap: 10px !important;
  }
  main > .last-visited-wrapper .product {
    gap: 8px;
  }
  .footer-item > strong {
    margin-bottom: 18px;
  }
  .footer-item.info > strong {
    margin-bottom: 10px;
  }
  .footer-item.info ul {
    columns: 1;
  }
  .footer-item.shortcut ul {
    grid-template-columns: minmax(0, 1fr);
  }
  .footer-item.contact > strong {
    margin-bottom: 2px;
  }
  .footer-bottom-bar {
    padding-block: 20px 55px;
    flex-direction: column;
    gap: 16px;
  }
  .category-header-wrapper {
    padding: var(--rowGap) 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }
  .category-header-wrapper:before {
    content: "";
    background-color: #E8E8E8;
    position: absolute;
    width: 101vw;
    height: 100%;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
    background-color: #F5F5F5;
  }
  .category-header-wrapper > * {
    max-width: 700px;
  }
  .listing-wrapper {
    gap: var(--rowGap);
  }
  .pagination {
    gap: 24px;
    margin-bottom: 12px;
  }
  .pagination a:after {
    font-size: 16px;
  }
  #breadcrumb {
    grid-column: span 2;
    margin-bottom: 0;
    padding-block: 10px;
    margin-bottom: calc(-1 * var(--rowGap));
  }
  .detail-main-wrapper {
    align-items: stretch;
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: relative;
  }
  #detail-images {
    align-items: center;
    flex-direction: column;
    gap: 24px;
    height: auto;
    left: auto;
    margin: 0 auto;
    position: relative;
    top: auto;
    width: 100%;
  }
  #detail-images .swiper-button-prev {
    left: 10px;
    height: 48px;
  }
  #detail-images .swiper-button-next {
    right: 10px;
    height: 48px;
  }
  .product-images-mini {
    order: initial;
    height: auto;
    width: 100%;
  }
  .product-images-mini .size-custom {
    padding: 0 !important;
  }
  .product-images-mini .size-custom.active {
    background-color: #313A49;
  }
  .product-images-mini .size-custom.active img {
    border: none !important;
  }
  .detail-headline {
    margin-bottom: -14px !important;
    text-align: center;
  }
  .detail-stars-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
  .detail-stars-wrapper > i {
    position: relative;
    top: 1px;
    margin-left: 8px;
  }
  .detail-stars-wrapper .stars-rating,
  .detail-stars-wrapper .stars-rating > div > ul {
    width: 80px;
  }
  #detail-info {
    gap: 20px;
  }
  .detail-pricing {
    align-items: flex-end;
  }
  .detail-pricing del,
  .detail-pricing .promo-text {
    bottom: 2px;
  }
  .detail-omnibus {
    margin-top: -16px !important;
  }
  .availability-wrapper .detail-availability {
    font-size: 18px;
    gap: 10px;
  }
  .availability-wrapper .detail-availability:before {
    top: 3px;
  }
  .availability-wrapper > :not(.detail-availability) {
    padding-left: 30px;
  }
  .detail-adding {
    margin-block: 8px;
  }
  .detail-amount {
    margin-right: 10px;
  }
  .detail-buttons {
    font-size: 18px;
    flex-direction: column;
    gap: 16px;
  }
  #detail-traits {
    max-width: 760px;
    gap: 0;
    margin-top: -4px;
  }
  #detail-traits .trait {
    flex-direction: column;
    gap: 6px;
    padding: 12px 0;
  }
  #detail-traits .trait b {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-start;
    flex: 1;
  }
  #detail-traits .trait .trait-desc {
    flex: 1;
    width: 100%;
  }
  .detail-main-info {
    margin-top: 0;
  }
  #detail-comments {
    gap: var(--rowGap);
    margin-bottom: 8px;
  }
  #detail-comments .comments-list {
    gap: 24px;
  }
  #detail-comments .comment {
    gap: 0 16px;
    grid-template-columns: minmax(0, 160px) minmax(0, 1fr);
  }
  #detail-comments .comment .comment-info .added-date {
    margin-block: 8px 8px;
  }
  #detail-comments .comment-body,
  #detail-comments .comment-response {
    padding: 24px;
  }
  #detail-comments .comment-response {
    margin-left: 18px;
  }
  @supports (-webkit-touch-callout: none) and (not (translate: none)) {
    .header-tools-wrapper > * {
      margin-left: 10px;
    }
    .hamburger-icon {
      display: grid;
      grid-auto-rows: max-content;
      grid-template-columns: 1fr;
      grid-template-rows: repeat(3, 6px);
      gap: 0;
      justify-items: center;
      align-items: center;
    }
    .tree-canvas {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
}
@media (max-width: 1100px) {
  .top-bar {
    gap: 12px;
  }
  .header-tools-wrapper {
    gap: 24px;
  }
  .main-banner b {
    font-size: 16px;
    bottom: 10px;
  }
  .promo-banners-wrapper {
    gap: 16px;
  }
}
@media (max-width: 1100px) and (max-width: 640px) {
  .top-bar:has(.header-shipment) .email {
    display: none !important;
  }
}
@media (max-width: 1100px) and (max-width: 1030px) {
  #newsletter {
    flex-direction: column;
    text-align: center;
    gap: 18px;
  }
  #newsletter b {
    margin-bottom: 8px;
  }
}
@media (max-width: 1100px) and (max-width: 500px) {
  .navigation-wrapper {
    flex-direction: column;
    gap: 12px;
  }
  .navigation-wrapper .dropdown-picker {
    width: 100%;
  }
  .navigation-wrapper .dropdown-picker[data-dropdown-type="sort"] {
    z-index: 11;
  }
  .navigation-wrapper .dropdown-picker button {
    width: 100%;
  }
  .dropdown-listing label {
    padding: 6px 20px;
  }
}
@media (max-width: 600px) {
  .detail-share-links-wrapper span {
    width: 100%;
  }
  .detail-pricing {
    align-items: center;
  }
  .detail-options dt {
    text-align: center;
    font-size: 16px;
  }
  .stars-rating.average-rating {
    top: -2px;
  }
  .gs-tabs nav {
    flex-direction: column;
  }
  .gs-tabs nav button {
    width: 100%;
  }
  .user-custom-content iframe {
    max-width: 100%;
  }
  .filter-trigger {
    padding: 0 20px;
    justify-content: flex-start;
    gap: 4px;
    width: fit-content;
  }
  .dropdown-picker[data-dropdown-type="pageSize"] {
    flex-grow: 1;
  }
  .dropdown-picker[data-dropdown-type="pageSize"] .dropdown-button {
    width: 100%;
  }
  .dropdown-picker:not([data-dropdown-type="sort"]):before {
    left: 13px;
  }
  .dropdown-picker:not([data-dropdown-type="sort"]) .dropdown-button {
    padding: 0 20px;
    min-width: 0;
  }
  .privacy-box {
    padding: 10px;
    gap: 10px;
  }
  .privacy-box__header {
    gap: 10px;
  }
  .privacy-box__header img {
    height: 20px;
  }
  .privacy-box__header strong {
    font-size: 14px;
  }
  .privacy-box__actions {
    flex-wrap: wrap;
  }
  .privacy-box__actions button {
    min-height: 35px;
  }
  .privacy-box__actions button.privacy-accept-all {
    width: 100%;
    order: -1;
    flex: 0 0 auto;
    min-height: 45px;
  }
  .pagination {
    gap: 4px;
  }
  .pagination a {
    width: 30px;
    height: 30px;
  }
  #breadcrumb {
    display: flex;
    margin-left: -10px;
    width: calc(100% + 20px);
    justify-content: flex-start;
    position: relative;
  }
  #breadcrumb .breadcrumb-end {
    display: block;
    position: absolute;
    top: 0;
    height: 100%;
    width: 20px;
    pointer-events: none;
  }
  #breadcrumb .breadcrumb-end:nth-of-type(1) {
    left: -7px;
    background-image: linear-gradient(to left, rgba(255, 255, 255, 0), #ffffff, #ffffff);
  }
  #breadcrumb .breadcrumb-end:nth-of-type(2) {
    right: -7px;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0), #ffffff, #ffffff);
  }
  #breadcrumb ul {
    flex-wrap: nowrap;
    overflow-y: auto;
    justify-content: flex-start;
    flex-direction: row-reverse;
    padding-bottom: 5px;
  }
  #breadcrumb li {
    white-space: nowrap;
  }
  #breadcrumb li:last-of-type {
    padding-right: 10px;
  }
  #breadcrumb li:first-of-type {
    padding-left: 10px;
  }
  #breadcrumb li:nth-of-type(1) {
    order: 10;
  }
  #breadcrumb li:nth-of-type(2) {
    order: 9;
  }
  #breadcrumb li:nth-of-type(3) {
    order: 8;
  }
  #breadcrumb li:nth-of-type(4) {
    order: 7;
  }
  #breadcrumb li:nth-of-type(5) {
    order: 6;
  }
  #detail-images .swiper-button-prev {
    left: 0;
  }
  #detail-images .swiper-button-next {
    right: 0;
  }
  #gsGalleryContainer > a {
    width: 100%;
  }
  .navigation-wrapper {
    padding-top: 0;
    border-top: 0;
  }
  .availability-modal-wrapper .wrapper {
    justify-content: center;
    gap: 10px;
  }
  .availability-modal-wrapper .wrapper > p {
    text-align: center;
  }
  .availability-modal-wrapper .wrapper > .availability-product {
    justify-content: center;
  }
  .availability-modal-wrapper .availability-product {
    gap: 10px;
    padding: 10px 0;
  }
  .availability-modal-wrapper .input-wrapper {
    flex-wrap: wrap;
  }
  .availability-modal-wrapper .input-wrapper > * {
    width: 100%;
  }
  .product-images {
    width: 100%;
  }
  .product-images .size-full {
    width: 100%;
    height: auto;
    aspect-ratio: 1;
  }
  .detail-amount {
    width: 100%;
    margin: 0 0 10px;
  }
  .pills-group:before,
  .remove-all-filters {
    font-size: 11px;
  }
  .single-pill {
    font-size: 11px;
    padding: 2px 5px;
  }
  #pills-container:not(:empty) {
    margin-bottom: 20px;
  }
  .detail-amount button:before {
    font-size: 22px !important;
  }
  .header-tools-wrapper {
    gap: 16px;
  }
  #detail-comments .comment {
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
  #detail-comments .comment-info {
    display: grid;
    grid-auto-rows: max-content;
    grid-template-rows: repeat(2, minmax(0, 1fr));
    gap: 8px 16px;
    margin-bottom: 10px;
    align-items: center;
  }
  #detail-comments .comment-info > * {
    margin: 0 !important;
  }
  #detail-comments .comment-info .author {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
  }
  #detail-comments .comment-info .stars-rating {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
    justify-self: end;
  }
  #detail-comments .comment-info .added-date {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
  }
  #detail-comments .comment-info .purchase-confirmed {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
    justify-self: end;
  }
  #detail-comments .comment-response {
    margin-left: 10px;
  }
}
@media (max-width: 600px) and (max-width: 374px) {
  .filter-trigger {
    padding: 0 15px;
    min-width: auto;
    justify-content: center;
  }
  .filter-trigger svg {
    width: 17px;
  }
}
@media (max-width: 600px) and (max-width: 460px) {
  .top-bar:has(.header-shipment) .header-social-links {
    display: none !important;
  }
}
@media (max-width: 600px) and (max-width: 400px) {
  .top-bar:has(.header-shipment) .header-shipment {
    margin-right: 0;
  }
  .top-bar:has(.header-shipment) .header-contact {
    display: none !important;
  }
  .top-bar {
    justify-content: center !important;
  }
  .header-social-links {
    display: none !important;
  }
  .shop-header-container {
    flex-direction: column;
    gap: 10px;
  }
  .shop-header-container .header-tools-wrapper {
    width: 100%;
    justify-content: space-evenly;
  }
}
@media (max-width: 600px) and (max-width: 570px) {
  .main-banners-wrapper {
    grid-template-columns: minmax(0, 1fr);
  }
  .main-banner b {
    bottom: 16px;
    font-size: 20px;
  }
}
@media (max-width: 600px) and (max-width: 450px) {
  .main-banner b {
    font-size: 16px;
    bottom: 8px;
  }
}
@media (max-width: 600px) and (max-width: 390px) {
  #newsletter b {
    font-size: 22px;
  }
}
