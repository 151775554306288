// IMPORTS
@import (reference) "../settings.less";

@media (max-width: 1680px) and (min-width: 1280px) {
    .inner {
        width: 1170px;
    }

    // SHOP SPECIFIC //////////////////////////////////////////////////////////////

    main.aside {
        grid-template-columns: 258px minmax(1px, 1fr);
    }

    .footer-item.info ul {
        column-gap: 32px;
    }

    .detail-main-wrapper {
        grid-template-columns: 400px minmax(0, 1fr);
        gap: 0 48px;
    }

    .detail-amount {
        width: 100px;
        margin-right: 8px;
    }

    .product-images,
    .product-images-mini {
        width: 400px;
    }

    .product-images .size-full {
        height: 400px;
    }

    #detail-comments {
        gap: 64px;
    }
}