// IMPORTS
@import (reference) "../settings.less";

@media (max-width: 600px) {
    .detail-share-links-wrapper span {
        width: 100%;
    }

    .detail-pricing {
        align-items: center;
    }

    .detail-options dt {
        text-align: center;
        font-size: 16px;
    }

    .stars-rating.average-rating {
        top: -2px;
    }

    .gs-tabs {

        nav {
            flex-direction: column;

            button {
                width: 100%;
            }
        }
    }

    .user-custom-content {

        iframe {
            max-width: 100%;
        }
    }

    .filter-trigger {
        padding: 0 20px;
        justify-content: flex-start;
        gap: 4px;
        width: fit-content;
    }

    .dropdown-picker[data-dropdown-type="pageSize"] {
        flex-grow: 1;

        .dropdown-button { 
            width: 100%;
        }
    }

    .dropdown-picker:not([data-dropdown-type="sort"]) {
        
        &:before {
            left: 13px;
        }
    
        .dropdown-button {
            padding: 0 20px;
            min-width: 0;
        }
    }

    .privacy-box {
        padding: 10px;
        gap: 10px;
    }

    .privacy-box__header {
        gap: 10px;

        img {
            height: 20px;
        }

        strong {
            font-size: 14px;
        }
    }

    .privacy-box__actions {
        flex-wrap: wrap;

        button {
            min-height: 35px;
        }
    }

    .privacy-box__actions button.privacy-accept-all {
        width: 100%;
        order: -1;
        flex: 0 0 auto;
        min-height: 45px;
    }

    .pagination {
        gap: 4px;

        a {
            width: 30px;
            height: 30px;
        }
    }

    #breadcrumb {
        display: flex;
        margin-left: -10px;
        width: ~"calc(100% + 20px)";
        justify-content: flex-start;
        position: relative;

        .breadcrumb-end {
            display: block;
            position: absolute;
            top: 0;
            top: 0;
            height: 100%;
            width: 20px;
            pointer-events: none;

            &:nth-of-type(1) {
                left: -7px;
                background-image: linear-gradient(to left, rgba(255, 255, 255, 0), rgb(255, 255, 255), rgb(255, 255, 255));
            }

            &:nth-of-type(2) {
                right: -7px;
                background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgb(255, 255, 255), rgb(255, 255, 255));
            }
        }

        ul {
            flex-wrap: nowrap;
            overflow-y: auto;
            justify-content: flex-start;
            flex-direction: row-reverse;
            padding-bottom: 5px;
        }

        li {
            white-space: nowrap;

            &:last-of-type {
                padding-right: 10px;
            }

            &:first-of-type {
                padding-left: 10px;
            }

            &:nth-of-type(1) {
                order: 10;
            }

            &:nth-of-type(2) {
                order: 9;
            }

            &:nth-of-type(3) {
                order: 8;
            }

            &:nth-of-type(4) {
                order: 7;
            }

            &:nth-of-type(5) {
                order: 6;
            }
        }
    }

    #detail-images .swiper-button-prev {
        left: 0;
    }

    #detail-images .swiper-button-next {
        right: 0;
    }

    #gsGalleryContainer > a {
        width: 100%;
    }

    .navigation-wrapper {
        padding-top: 0;
        border-top: 0;
    }

    .availability-modal-wrapper .wrapper {
        justify-content: center;
        gap: 10px;

        > p {
            text-align: center;
        }

        > .availability-product {
            justify-content: center;
        }
    }

    .availability-modal-wrapper .availability-product {
        gap: 10px;
        padding: 10px 0;
    }

    .availability-modal-wrapper .input-wrapper {
        flex-wrap: wrap;

        > * {
            width: 100%;
        }
    }

    .product-images {
        width: 100%;
    }

    .product-images .size-full {
        width: 100%;
        height: auto;
        aspect-ratio: 1;
    }

    .detail-amount {
        width: 100%;
        margin: 0 0 10px;
    }

    .pills-group:before,
    .remove-all-filters {
        font-size: 11px;
    }

    .single-pill {
        font-size: 11px;
        padding: 2px 5px;
    }

    #pills-container:not(:empty) {
        margin-bottom: 20px;
    }

    .detail-amount button:before {
        font-size: 22px !important;
    }

    @media (max-width: 374px) {
        .filter-trigger {
            padding: 0 15px;
            min-width: auto;
            justify-content: center;

            svg {
                width: 17px;
            }
        }
    }

    // SHOP SPECIFIC //////////////////////////////////////////////////////////////
    .header-tools-wrapper {
        gap: 16px;
    }

    @media (max-width: 460px) {
        .top-bar:has(.header-shipment) {
            .header-social-links {
                display: none !important;
            }
        }
    }

    @media (max-width: 400px) {
        .top-bar:has(.header-shipment) {

            .header-shipment {
                margin-right: 0;
            }

            .header-contact {
                display: none !important;
            }
        }

        .top-bar {
            justify-content: center !important;
        }

        .header-social-links {
            display: none !important;
        }

        .shop-header-container {
            flex-direction: column;
            gap: 10px;

            .header-tools-wrapper {
                width: 100%;
                justify-content: space-evenly;
            }
        }
    }

    @media (max-width: 570px) {
        .main-banners-wrapper {
            grid-template-columns: minmax(0, 1fr);
        }

        .main-banner {
            b {
                bottom: 16px;
                font-size: 20px;
            }
        }
    }

    @media (max-width: 450px) {
        .main-banner b {
            font-size: 16px;
            bottom: 8px;
        }
    }

    @media (max-width: 390px) {
        #newsletter b {
            font-size: 22px;
        }
    }

    #detail-comments {

        .comment {
            display: flex;
            flex-direction: column;
            align-items: stretch;
        }

        .comment-info {
            .grid();
            grid-template-rows: repeat(2, minmax(0, 1fr));
            gap: 8px 16px;
            margin-bottom: 10px;
            align-items: center;

            > * {
                margin: 0 !important;
            }

            .author {
                grid-column: 1 / 2;
                grid-row: 1 / 2;
            }

            .stars-rating {
                grid-column: 2 / 3;
                grid-row: 1 / 2;
                justify-self: end;
            }

            .added-date {
                grid-column: 1 / 2;
                grid-row: 2 / 3;
            }

            .purchase-confirmed {
                grid-column: 2 / 3;
                grid-row: 2 / 3;
                justify-self: end;
            }
        }

        .comment-response {
            margin-left: 10px;
        }
    }
}