// IMPORTS
@import (reference) "../settings.less";

@media (max-width: 1279px) {
    :root {
        --rowGap: 25px;
        --fontSize: 13px;
        --lineHeight: 1.4;
    }

    .detail-omnibus {
        align-self: flex-start;
        margin-top: 0;
        font-size: 11px;
    }

    .footer-bottom-bar {
        padding: 30px 0 100px;
    }

    #container {
        overflow: clip;
    }

    .detail-omnibus {
        text-align: left;
    }

    main {
        padding-bottom: var(--rowGap) !important;
    }

    main.aside {
        grid-template-columns: minmax(0, 1fr);
        grid-gap: var(--rowGap) 0;
    }

    .inner {
        width: 100%;
        padding: 0 10px;
    }

    * {
        -webkit-tap-highlight-color: rgba(189, 195, 199, .75);
        -webkit-text-size-adjust: none;
    }

    body.privacy-box-visible {
        overflow: hidden;
        
        #container {
            filter: blur(5px);
            pointer-events: none;
        }
    }

    body:has(.left-side-cookies) {

        .privacy-box-trigger {
            bottom: 8px;
            width: 35px;
            height: 35px;

            &.right-side-cookies {
                right: 8px;
            }

            &.left-side-cookies {
                left: 8px;
            }
        }
    }

    .privacy-box {
        bottom: auto;
        right: auto;
        left: 15px !important;
        right: 15px !important;
        top: 50%;
        transform: translateY(-50%);
        max-width: 100vw;
        max-height: ~"calc(100% - 40px)";
    }

    input[type=text],
    input[type=password],
    input[type=email],
    select,
    textarea {
        font-size: 16px;
        padding: 0 14px;
    }

    textarea {
        padding: 10px 14px;
    }

    #facebook-box,
    .detail-loupe {
        display: none;
    }

    #detail-images {

        .swiper-button-prev,
        .swiper-button-next {
            height: 80px;
            margin-top: -40px !important;
        }

        .swiper-button-prev {
            left: -45px;
        }

        .swiper-button-next {
            right: -45px;
        }
    }

    .headline {
        font-size: 24px !important;
        margin-bottom: 10px;
    }

    #content {
        width: 100%;
        clear: both;
    }

    .sidebar {
        width: auto;
        display: contents;

        .sidebar-tabs {
            display: none;
        }
    }

    // SEARCH ENGINE
    #search-engine {
        display: none;
    }

    .newsletter-bg {
        background-image: none !important;
    }

    .newsletter-input {
        width: 100%;
    }

    .footer-content {
        flex-direction: column;
        padding: 20px 0;
    }

    .footer-item {
        width: 100% !important;
    }

    body:has(.gs-filtr-container) .filter-trigger {
        display: flex;
    }

    // SORTER
    .dropdown-picker {
        margin-top: 7px;

        &:before {
            display: block;
            color: @font-color;
            font-weight: normal;
            left: 12px !important;
        }

        .dropdown-button {
            height: 40px;
            padding-inline: 18px 70px;
            min-width: 160px !important;

            &:after {
                height: 40px;
                width: 40px;
            }
        }
    }

    [data-dropdown-type="sort"] .dropdown-button {
        min-width: 200px !important;
    }

    .dropdown-listing {
        padding: 2px 0;

        label {
            padding: 6px 18px;
        }
    }

    // FILTER
    #filter {
        display: none;
    }

    .product-images-mini {
        width: 100%;
        height: auto;
        margin-top: 10px;

        .swiper-wrapper {
            transform: none !important;
            width: auto;
            justify-content: center;
        }

        .swiper-slide {
            width: 10px;
            height: 10px;
            background-color: @border-color;
            border-radius: 50%;
            margin: 0 5px;
        }

        img {
            display: none !important;
        }
    }

    // OTHER
    .user-custom-content p {
        margin-bottom: 10px;
    }

    hr {
        margin: 20px 0;
    }

    .overlay-visible {
        overflow: hidden;
        height: 100dvh;
        width: 100dvw;
        position: fixed;
        top: 0;
    }

    main:not(.aside) #tree {
        display: block;
    }

    .tree-canvas {
        position: fixed;
        background: rgb(0, 0, 0) none repeat scroll 0% 0%;
        will-change: opacity;
        transition: opacity 300ms ease 0s;
        inset: 0px;
        z-index: 1001;
        pointer-events: none;
        opacity: 0;
    }

    #tree {
        position: fixed;
        top: 0;
        left: 0;
        transform: translate3d(-100%, 0, 0);
        overflow: hidden;
    }

    // SHOP SPECIFIC //////////////////////////////////////////////////////////////

    :root {
        --iconSize: 20px;
    }

    .sidebar {
        display: contents;

        > * {
            display: none;
        }

        .tree-canvas,
        #tree {
            display: block;
        }
    }

    .top-bar {
        padding-block: 4px;
        gap: 24px;
    }

    .header-shipment:before {
        margin-right: 6px;
    }

    .header-contact {
        gap: 18px;

        > a {
            padding-left: 28px;
        }
    }

    .shop-header {
        position: sticky;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 1000;
        background-color: white;
        .shadow();
        transition: transform .2s ease-in-out;
    }

    .shop-header-container {
        padding: 8px 0;
    }

    .logo {
        height: 32px;
    }


    #cart-box,
    #wishlist-box,
    .log-button,
    .search-engine-trigger,
    .tree-trigger {
        display: block;
        cursor: pointer;
        position: relative;
        padding-top: 25px;

        span,
        b {
            display: none;
        }

        &:before {
            font-family: icomoon;
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            font-size: 24px;
            line-height: 1;
            color: @header-color;
        }

        em {
            color: @font-color;
            font-style: normal;
            text-transform: uppercase;
            font-size: 9px;
            display: block;
            line-height: 14px;
        }
    }

    .search-engine-trigger {

        &:before {
            font-size: 30px;
            top: -2px;
            content: "\e916";
        }
    }

    .log-button {

        &:before {
            content: "\e915";
        }
    }

    #wishlist-box {

        &:before {
            content: "\e914";
        }

        &[data-wishlist-count="0"]:after {
            display: none;
        }

        &:after {
            right: 5px !important;
        }
    }

    #cart-box {

        &:before {
            content: "\e913";
        }
    }

    #wishlist-box:after,
    #cart-box i {
        font-size: 0;
        top: 1px;
        right: 1px;
        left: initial;
        width: 8px;
        height: 8px;
    }

    .hamburger-icon {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 25px;
        display: flex;
        flex-direction: column;
        gap: 4px;
        justify-content: center;

        span {
            display: block;
            width: 100%;
            height: 2px;
            border-radius: 1px;
            background-color: @header-color;

            &:nth-child(1) {
            }

            &:nth-child(2) {
            }

            &:nth-child(3) {
            }
        }
    }

    .mainmenu {
        display: none !important;
    }

    .below-header-bar {
        padding: 4px 0;
    }

    // 1279 BASE
    .product {
        gap: 10px;
        padding: 10px;

        .product-price {
            height: 32px;
        }

        .product-wishlist,
        .product-add {
            width: 32px;
            height: 32px;

            &:after {
                font-size: 20px;
            }
        }
    }

    .see-all {
        margin-top: 16px;
    }

    // 1279 MAINPAGE
    body[data-type="mainpage"] #content {
        margin-top: 16px;
    }

    .main-banners-wrapper {
        gap: 16px;
    }

    .default-banner > b {
        font-size: 20px;
    }

    .mainpage-greeting {
        padding: var(--rowGap) 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;

        &:before {
            .full-bg();
            background-color: @light-bg;
        }

        > * {
            max-width: 700px;
        }

        .headline {
            margin-bottom: 16px !important;
        }
    }

    .promo-banner {
        padding: 12px;

        figure {
            width: 32px;
        }

        b {
            font-size: 16px;
        }
    }

    // 1279 FOOTER
    #newsletter {
        gap: 24px;

        b {
            font-size: 27px;
        }
    }

    main > .last-visited-wrapper {
        --prodGap: 10px !important;

        .product {
            gap: 8px;
        }
    }

    .footer-item > strong {
        margin-bottom: 18px;
    }

    .footer-item.info {

        > strong {
            margin-bottom: 10px;
        }

        ul {
            columns: 1;
        }
    }

    .footer-item.shortcut ul {
        grid-template-columns: minmax(0, 1fr);
    }

    .footer-item.contact > strong {
        margin-bottom: 2px;
    }

    .footer-bottom-bar {
        padding-block: 20px 55px;
        flex-direction: column;
        gap: 16px;
    }

    // 1279 LISTING
    .category-header-wrapper {
        padding: var(--rowGap) 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;

        &:before {
            .full-bg();
            background-color: @light-bg;
        }

        > * {
            max-width: 700px;
        }
    }

    .listing-wrapper {
        gap: var(--rowGap);
    }

    .pagination {
        gap: 24px;
        margin-bottom: 12px;

        a:after {
            font-size: 16px;
        }
    }

    #breadcrumb {
        grid-column: span 2;
        margin-bottom: 0;
        padding-block: 10px;
        margin-bottom: calc(-1 * var(--rowGap));
    }

    // 1279 DETAIL
    .detail-main-wrapper {
        align-items: stretch;
        display: flex;
        flex-direction: column;
        gap: 20px;
        position: relative;
    }

    #detail-images {
        align-items: center;
        flex-direction: column;
        gap: 24px;
        height: auto;
        left: auto;
        margin: 0 auto;
        position: relative;
        top: auto;
        width: 100%;

        .swiper-button-prev {
            left: 10px;
            height: 48px;
        }

        .swiper-button-next {
            right: 10px;
            height: 48px;
        }
    }

    .product-images-mini {
        order: initial;
        height: auto;
        width: 100%;

        .size-custom {
            padding: 0 !important;

            &.active {
                background-color: @main-color;

                img {
                    border: none !important;
                }
            }
        }
    }

    .detail-headline {
        margin-bottom: -14px !important;
        text-align: center;
    }
    
    .detail-stars-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;

        > i {
            position: relative;
            top: 1px;
            margin-left: 8px;
        }

        .stars-rating,
        .stars-rating > div > ul {
            width: 80px;
        }
    }

    #detail-info {
        gap: 20px;
    }

    .detail-pricing {
        align-items: flex-end;

        del,
        .promo-text {
            bottom: 2px;
        }
    }

    .detail-omnibus {
        margin-top: -16px !important;
    }

    .availability-wrapper {

        .detail-availability {
            font-size: 18px;
            gap: 10px;

            &:before {
                top: 3px;
            }
        }

        > :not(.detail-availability) {
            padding-left: 30px;
        }
    }

    .detail-adding {
        margin-block: 8px;
    }

    .detail-amount {
        margin-right: 10px;
    }

    .detail-buttons {
        font-size: 18px;
        flex-direction: column;
        gap: 16px;
    }

    #detail-traits {
        max-width: 760px;
        gap: 0;
        margin-top: -4px;

        .trait {
            flex-direction: column;
            gap: 6px;
            padding: 12px 0;

            b {
                display: flex;
                align-items: center;
                width: 100%;
                justify-content: flex-start;
                flex: 1;
            }

            .trait-desc {
                flex: 1;
                width: 100%;
            }
        }
    }

    .detail-main-info {
        margin-top: 0;
    }


    #detail-comments {
        gap: var(--rowGap);
        margin-bottom: 8px;

        .comments-list {
            gap: 24px;
        }

        .comment {
            gap: 0 16px;
            grid-template-columns: minmax(0, 160px) minmax(0, 1fr);
            
            .comment-info .added-date {
                margin-block: 8px 8px;
            }
        }

        .comment-body,
        .comment-response {
            padding: 24px;
        }

        .comment-response {
            margin-left: 18px;
        }
    }

    // iOS 12 fixes
    @supports (-webkit-touch-callout: none) and (not (translate: none)) {
        .header-tools-wrapper {
            
            > * {
                margin-left: 10px;
            }
        }

        .hamburger-icon {
            .grid();
            grid-template-columns: 1fr;
            grid-template-rows: repeat(3, 6px);
            gap: 0;
            justify-items: center;
            align-items: center;
        }

        .tree-canvas {
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
    }
}