// IMPORTS
@import (reference) "../settings.less";

@media (max-width: 1100px) {

    // SHOP SPECIFIC //////////////////////////////////////////////////////////////

    .top-bar {
        gap: 12px;
    }


    @media (max-width: 640px) {
        .top-bar:has(.header-shipment) .email {
            display: none !important;
        }
    }

    .header-tools-wrapper {
        gap: 24px;
    }

    .main-banner {

        b {
            font-size: 16px;
            bottom: 10px;
        }
    }

    .promo-banners-wrapper {
        gap: 16px;
    }

    @media (max-width: 1030px) {
        #newsletter {
            flex-direction: column;
            text-align: center;
            gap: 18px;

            b {
                margin-bottom: 8px;
            }
        }
    }

    @media (max-width: 500px) {
        .navigation-wrapper {
            flex-direction: column;
            gap: 12px;

            .dropdown-picker {
                width: 100%;

                &[data-dropdown-type="sort"] {
                    z-index: 11;
                }

                button {
                    width: 100%;
                }
            }
        }

        .dropdown-listing label {
            padding: 6px 20px;
        }
    }
}